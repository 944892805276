import React from 'react'
import Profile from '../features/profile/Profile'

function ProfilePage() {
  return (
    <div>
        <Profile />
    </div>
  )
}

export default ProfilePage