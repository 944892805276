import React from "react";
import { Link } from "react-router-dom";

function Footer({ bg, text }) {
  return (
    <div className={`print:hidden ${bg}`}>
      <footer className={`body-font ${bg}`}>
        <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
          <Link
            to="/"
            className={`flex title-font font-medium items-center md:justify-start justify-center ${text}`}
          >
            <img src="./image.png" alt="logo" className="size-16" />
            <span className={`ml-3 text-xl ${text}`}>Patel Sangathan</span>
          </Link>
          <p className={`text-sm ${text} sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4`}>
            © 2024 Patel Sangathan —
              @patelsangathan
          </p>
          
        </div>
      </footer>
    </div>
  );
}

export default Footer;
