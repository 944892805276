import React from 'react';
import { Link } from 'react-router-dom';

function Navbar({ bg, text }) {
  return (
    <header className={`lg:px-16 px-4 ${bg} ${text} flex flex-wrap justify-between  items-center py-4 shadow-md print:hidden`}>
      {/* <div className=" flex justify-between items-center"> */}
        <div className="flex gap-2 font-bold text-xl ">
        <Link to={"/"} className="flex gap-2  font-bold text-2xl  items-center justify-center">
        <img src="./image.png" alt="logo" className='size-16'/>
        <h1 className=''>
        Patel Sangathan
        </h1>
        </Link>
        </div>
      {/* </div> */}

      <label htmlFor="menu-toggle" className="pointer-cursor md:hidden block">
        <svg className="fill-current text-gray-900"
          xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </label>
      <input className="hidden" type="checkbox" id="menu-toggle" />

      <div className="hidden md:flex md:items-center md:w-auto w-full" id="menu">
        <nav>
          <ul className={`md:flex items-center justify-between text-base ${text} pt-4 md:pt-0`}>
            <li><Link className="md:p-4 py-3 px-0 block" to={"/register"}>Register</Link></li>
            <li><Link className="md:p-4 py-3 px-0 block" to={"/search"}>Search</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
