import React from 'react'
import Search from '../features/search/Search'

function SearchPage() {
  return (
    <div>
        <Search />
    </div>
  )
}

export default SearchPage